<template>
  <v-dialog
    :model-value="modelValue"
    :width="width"
    @keyup.esc="emit('closeDialog')"
    @update:model-value="emit('closeDialog')"
  >
    <v-card id="scroll-target">
      <v-spacer
        v-scroll:#scroll-target="onScroll"
        :class="{ 'shadow-md': showShadow, 'shadow-none': !showShadow }"
        class="sticky top-0 z-[100] py-4 px-5 bg-[#FCFCFC]"
      >
        <v-row>
          <v-col class="flex items-center space-x-2" cols="8">
            <v-spacer class="flex items-center">
              <slot name="icon" />
              <span id="title" class="ml-2 text-xs md:text-sm not-italic font-medium line-clamp-2">
                {{ title }}
              </span>
            </v-spacer>
          </v-col>
          <v-col class="flex flex-row justify-end gap-1 md:gap-4" cols="4">
            <v-menu v-if="mobile && showDotsMenu">
              <template v-slot:activator="{ props }">
                <v-btn color="black" icon="mdi-dots-vertical" v-bind="props" variant="text"></v-btn>
              </template>
              <v-list>
                <v-list-item v-for="activator in actions" :key="activator.id">
                  <v-list-item-title class="flex flex-row gap-4" @click="activator.action">
                    <component :is="activator.icon" />
                    {{ activator.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div v-else class="flex flex-row justify-end h-full gap-4">
              <button
                v-if="deleteButton"
                v-tooltip="'Excluir permanentemente'"
                class="flex border-solid border-[1px] border-[#F5260A] text-[#F5260A] py-2 px-4 items-center justify-center rounded-md !text-xs line-clamp-1 bg-[#fff]"
                @click="deleteDialog"
              >
                <v-icon size="20">mdi-trash-can-outline</v-icon>
                <span class="line-clamp-1 md:hidden">Apagar</span>
              </button>

              <button
                v-if="duplicateButton"
                :disabled="buttonDuplicateDisabled"
                class="border-[1px] flex items-center justify-center py-2 px-4 border-solid border-[#1c86e3] rounded-md shadow-sm !text-xs !w-[9.5rem]"
                @click="duplicateDialog"
              >
                <DuplicateDocumenteIcon />
                <span class="edit_text line-clamp-1">{{ buttonDuplicateText }}</span>
              </button>

              <button
                v-if="editButton"
                :disabled="buttonEditDisabled"
                class="border-[1px] flex items-center justify-center py-2 px-4 border-solid border-[#1c86e3] rounded-md shadow-sm !text-xs !w-[8.5rem]"
                @click="editDialog"
              >
                <EditDocumentIcon />
                <span class="edit_text line-clamp-1">{{ buttonEditText }}</span>
              </button>

              <button
                v-if="accessibilityButton"
                :disabled="!accessibilityButton"
                class="border-[1px] flex items-center cursor-pointer justify-center py-1 px-2 border-solid rounded-lg shadow-lg"
                @click="accessibilityDialog"
              >
                <ContrastIcon />
              </button>

              <BasePrimaryButton
                v-if="saveButton"
                :disabled="buttonSaveDisabled"
                :text="buttonSaveText"
                @click="saveDialog"
              />
            </div>
            <button @click="closeDialog">
              <v-icon size="20">mdi-close</v-icon>
            </button>
          </v-col>
        </v-row>
      </v-spacer>
      <v-divider class="border-opacity-100"></v-divider>
      <v-spacer class="p-5">
        <v-spacer>
          <slot name="image" />
        </v-spacer>
        <v-spacer>
          <slot name="form" />
        </v-spacer>
        <slot name="list" />
        <slot />
      </v-spacer>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import EditDocumentIcon from '../icons/EditDocumentIcon.vue'
import DuplicateDocumenteIcon from '../icons/DuplicateDocumenteIcon.vue'
import ContrastIcon from '@/components/icons/ContrastIcon.vue'
import { useDisplay } from 'vuetify'
import { type PropType, ref } from 'vue'

const { mobile } = useDisplay()

defineProps({
  modelValue: Boolean,
  deleteButton: Boolean,
  accessibilityButton: {
    type: Boolean,
    default: false
  },
  saveButton: {
    type: Boolean,
    default: true
  },
  editButton: {
    type: Boolean,
    default: false
  },
  duplicateButton: {
    type: Boolean,
    default: false
  },
  title: String,
  buttonDeleteText: String,
  buttonSaveText: {
    type: String,
    default: 'Salvar'
  },
  buttonEditText: {
    type: String,
    default: 'Editar'
  },
  buttonDuplicateText: {
    type: String,
    default: 'Duplicar'
  },
  width: {
    type: [Number, String],
    default: 510
  },
  buttonSaveDisabled: {
    type: Boolean,
    default: false
  },
  buttonEditDisabled: {
    type: Boolean,
    default: false
  },
  buttonDuplicateDisabled: {
    type: Boolean,
    default: false
  },
  showMenuItem: {
    type: Boolean,
    default: false
  },
  showDotsMenu: {
    type: Boolean,
    default: false
  },
  actions: {
    type: Array as PropType<ActionType[]>
  }
})

const emit = defineEmits([
  'closeDialog',
  'saveDialog',
  'editDialog',
  'deleteDialog',
  'duplicateDialog',
  'accessibilityDialog'
])

const closeDialog = () => emit('closeDialog')
const saveDialog = () => emit('saveDialog')
const editDialog = () => emit('editDialog')
const deleteDialog = () => emit('deleteDialog')
const duplicateDialog = () => emit('duplicateDialog')
const accessibilityDialog = () => emit('accessibilityDialog')
const showShadow = ref(false)

const onScroll = (e: any) => {
  showShadow.value = e?.target.scrollTop > 0
}

export type ActionType = {
  id: number
  title: string
  action: () => void
  icon: any
}
</script>
