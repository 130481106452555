<template>
  <div class="relative">
    <div
      class="flex px-[10px] py-[10px] border-[#E8F3FC] items-start self-stretch rounded-[20px] border-2"
    >
      <div
        :class="{
          'absolute left-6 -top-[12px] px-1 bg-white': !mobile,
          'relative px-1 bg-white': mobile
        }"
      >
        <span class="text-[#808080] text-xs font-normal not-italic">Resposta</span>
      </div>
      <span
        class="text-base font-normal not-italic text-[#333333]"
        style="letter-spacing: 0"
        v-html="question.answers[0]"
      ></span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDisplay } from 'vuetify'

const { mobile } = useDisplay()

defineProps(['question'])
</script>
