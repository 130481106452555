<template>
  <v-toolbar :style="fontSm" class="rounded-b-xl px-[32px] mb-[20px]" color="white" height="96">
    <v-row>
      <v-col class="flex items-center" cols="6">
        <LogoPruvoOnlyIcon v-if="isPruvo" class="mr-[24px]" />
        <img
          v-else-if="providerLogo"
          :src="providerLogo"
          alt="Logo da instituição"
          class="mr-[24px]"
          width="64"
        />
        <v-avatar class="mr-[12px]">
          <img
            :src="user?.avatar"
            onerror="this.src = 'https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1-300x300.png'"
          />
        </v-avatar>
        <div class="flex flex-col items-end">
          <p class="mb-0 mr-4 font-medium">{{ user?.name }}</p>
        </div>
      </v-col>
      <v-col class="flex justify-end items-center" cols="6">
        <div
          v-show="showTimer && timer"
          class="bg-[#D2E7F9] py-[8px] px-[12px] text-[#1C86E3] flex items-center justify-center rounded-xl"
        >
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C22.9966 9.08367 21.8365 6.28778 19.7744 4.22563C17.7122 2.16347 14.9163 1.00344 12 1ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C20.9974 14.3861 20.0483 16.6738 18.361 18.361C16.6738 20.0483 14.3861 20.9974 12 21ZM18 12C18 12.2652 17.8946 12.5196 17.7071 12.7071C17.5196 12.8946 17.2652 13 17 13H12C11.7348 13 11.4804 12.8946 11.2929 12.7071C11.1054 12.5196 11 12.2652 11 12V6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V11H17C17.2652 11 17.5196 11.1054 17.7071 11.2929C17.8946 11.4804 18 11.7348 18 12Z"
              fill="#1C86E3"
            />
          </svg>
          <span class="ml-[10px] font-bold">{{ timer }}</span>
        </div>
        <div v-if="!mobile" class="flex justify-between items-center mx-[20px]">
          <v-tooltip location="top start" origin="auto" text="Diminuir fonte">
            <template v-slot:activator="{ props }">
              <v-btn
                :disabled="fontSize === fontZoomStore.min"
                class="!border-2"
                color="blue"
                icon="mdi-minus"
                size="x-small"
                v-bind="props"
                variant="outlined"
                @click="fontZoomStore.setFontZoom('sub')"
              />
            </template>
          </v-tooltip>
          <span class="font-bold mx-[12px]">aA</span>
          <v-tooltip location="top start" origin="auto" text="Aumentar fonte">
            <template v-slot:activator="{ props }">
              <v-btn
                :disabled="fontSize === fontZoomStore.max"
                class="!border-2"
                color="blue"
                icon="mdi-plus"
                size="x-small"
                v-bind="props"
                variant="outlined"
                @click="fontZoomStore.setFontZoom('add')"
              />
            </template>
          </v-tooltip>
        </div>
        <v-btn
          :class="{
            '!border-2 text-none': !mobile,
            '!border-2 text-none ml-3': mobile
          }"
          color="blue"
          variant="outlined"
          @click="btnAction"
        >
          <span :style="fontSm">{{
            !mobile ? (isShowResolution ? 'Revisar e finalizar' : 'Finalizar') : 'Revisar'
          }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-toolbar>
</template>

<script lang="ts" setup>
import { db } from '@/config/firebase'
import LogoPruvoOnlyIcon from '../icons/LogoPruvoOnlyIcon.vue'
import { computed, inject, onMounted, type Ref, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { endApplicationAndSave, getApplicationQuestions } from '@/services/application.service'
import moment from 'moment'
import { useFontZoomStore } from '@/stores/font'
import { useTimerCounterStore } from '@/stores/counter'
import { getBrazilianDate } from '@/utils/date'
import { useDisplay } from 'vuetify'
import { doc, onSnapshot } from 'firebase/firestore'
import { getActiveOrganizationData } from '@/services/organization.service'
import { getProviderById } from '@/services/provider.service'
import { useAuth } from '@/composables/useAuth'
import { useConfettiStore } from '@/stores/confetti'
import { useSnackbarStore } from '@/stores/snakebar'
import { useLoadingStore } from '@/stores/loading'

const isShowResolution = inject('isShowResolution') as Ref
const isPruvo = ref(false)
const providerLogo = ref<string>('')
const { user } = useAuth()
const { mobile } = useDisplay()

const props = defineProps({
  showTimer: {
    type: Boolean,
    default: true
  }
})

const { showTimer } = toRefs(props)

const btnAction = async () => {
  if (isShowResolution.value) {
    isShowResolution.value = false
    return
  }

  await endApplication()
}

const route = useRoute()
const router = useRouter()
const fontZoomStore = useFontZoomStore()
const confettiStore = useConfettiStore()
const snackbarStore = useSnackbarStore()
const loadingStore = useLoadingStore()

const timer: any = ref('')
let timerId: any = null
const applicationData = ref({} as any)

const fontSize = computed(() => fontZoomStore.getFontZoom)
const fontSm = computed(() => useFontZoomStore().getFontSm)

onMounted(async () => {
  clearInterval(timerId)

  const data = (await getApplicationQuestions(route.params.applicationId as string)) as any

  if (!data) {
    return router.push({ name: 'dashboard' })
  }

  applicationData.value = data.application

  onSnapshot(
    doc(db, 'users', data.userApplication.user_id, 'applications', data.userApplication.id),
    (doc) => {
      data.userApplication = doc.data()

      const { duration, started_at } = data.userApplication
      const finishesAt = getBrazilianDate(moment.unix(started_at?.seconds)).add(duration, 'minutes')

      clearInterval(timerId)

      timerId = setInterval(async () => {
        const diff = moment.duration(finishesAt.diff(moment()))

        if (duration) {
          timer.value = `${formatTime(diff.hours())}:${formatTime(diff.minutes())}:${formatTime(
            diff?.seconds()
          )}`
        } else {
          timer.value = null
        }
      }, 1000)
    }
  )

  await getProviderLogo()
})

const formatTime = (time: number) => {
  return time.toString().padStart(2, '0')
}

const endApplication = async () => {
  try {
    loadingStore.startLoading()
    clearInterval(timerId)
    useTimerCounterStore().stopCounter()
    const timeApplicationSpent = useTimerCounterStore().getCounter
    await endApplicationAndSave(
      route.params.applicationId as string,
      applicationData.value.id,
      timeApplicationSpent
    )
    await router.push({ name: 'dashboard' })
    confettiStore.startConfetti()
    setTimeout(() => confettiStore.stopConfetti(), 3000)
  } catch (e) {
    console.log(e)
    snackbarStore.showSnackbar(
      ['Ah não! Não conseguimos terminar a avaliação. Que tal tentar mais uma vez?'],
      'error'
    )
  } finally {
    loadingStore.stopLoading()
  }
}

const getProviderLogo = async () => {
  const organizationData = await getActiveOrganizationData()
  const providerData = await getProviderById(organizationData?.provider_id)

  if (organizationData?.provider_id === 'PRUVO') {
    isPruvo.value = true
    return
  }

  providerLogo.value = providerData?.logo
}
</script>

<style>
.v-list {
  border: 1px solid #1c86e3;
}

.v-list-item {
  border-bottom: 1px solid #1c86e3;
}

.v-list-item:last-child {
  border-bottom: 0;
}
</style>
