<template>
    <div :class="{
        'mx-10': !mobile,
        'mx-2': mobile
    }">
        <v-toolbar height="96" color="white" class="rounded-b-xl px-[32px] mb-[20px] position-fixed !w-[95%]"
            style="z-index: 99999 !important" :style="fontSm">
            <v-row>
                <v-col cols="6" class="flex items-center">
                    <LogoPruvoOnlyIcon class="mr-[24px]" />
                    <v-avatar class="mr-[12px]">
                        <img :src="user?.avatar"
                            onerror="this.src = 'https://www.ecp.org.br/wp-content/uploads/2017/12/default-avatar-1-300x300.png'" />
                    </v-avatar>
                    <div class="flex flex-col items-end">
                        <p class="mb-0 mr-4 font-medium">{{ user?.name }}</p>
                    </div>
                </v-col>
                <v-col cols="6" class="flex justify-end items-center">
                    <div class="flex justify-between items-center mx-[20px]" v-if="!mobile">
                        <v-btn class="!border-2" size="x-small" icon="mdi-minus" color="blue" variant="outlined"
                            @click="fontZoomStore.setFontZoom('sub')" :disabled="fontSize === fontZoomStore.min" />
                        <span class="font-bold mx-[12px]">aA</span>
                        <v-btn class="!border-2" size="x-small" icon="mdi-plus" color="blue" variant="outlined"
                            :disabled="fontSize === fontZoomStore.max" @click="fontZoomStore.setFontZoom('add')" />
                    </div>
                    <v-btn @click="btnAction" color="blue" class="!border-2 text-none" variant="outlined">
                        <span :style="fontSm">Sair</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-toolbar>
        <v-row no-gutters>
            <v-col class="mt-[120px]">
                <v-row>
                    <v-col cols="12">
                        <v-sheet class="py-[20px] px-[24px] mr-[20px] rounded-xl shadow-sheet">
                            <v-main>
                                <div class="mb-[16px] font-medium" :style="fontXl">
                                    {{ data.application?.name }}
                                </div>
                                <div>
                                    <h2 class="font-bold" :style="fontBase">Instruções</h2>
                                    <p :style="fontSm" v-html="data.application?.instructions"></p>
                                </div>
                            </v-main>
                        </v-sheet>
                    </v-col>
                    <template v-for="subFormQuestions of data.questions" :key="subFormQuestions.subFormOrder">
                        <v-col cols="12" v-for="question, index of (collect(subFormQuestions.questions) as any)
        .sortBy('order')
        .all()" class="question-active" :key="question.id" :id="question.id">
                            <v-sheet class="py-[20px] px-[24px] mr-[20px] rounded-xl shadow-sheet">
                                <v-main>
                                    <div class="flex justify-between">
                                        <div class="flex items-center">
                                            <div class="mr-[12px]">
                                                <v-chip class="!font-medium" variant="elevated" rounded color="blue"
                                                    :style="fontSm">{{
        index + 1 }}</v-chip>
                                            </div>
                                            <div class="mr-[12px]">
                                                <v-chip class="!font-medium" variant="elevated" rounded color="blue"
                                                    :style="fontSm">{{
        getQuestionType(question.type)
    }}</v-chip>
                                            </div>
                                            <div>
                                                <v-chip class="!font-medium" rounded color="blue">
                                                    <span :style="fontSm">?</span>
                                                </v-chip>
                                            </div>
                                        </div>
                                        <div class="bg-[#1C86E3] text-white py-2 px-3 rounded-lg text-sm">
                                            <div class="text-right text-xs">
                                                Nota
                                            </div>
                                            <div class="text-right font-medium text-base">
                                                {{ getQuestionFinalGrade(question) }}<span
                                                    class="tespanxt-xs opacity-70">/{{
        question.grade }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-[12px] mb-[20px]" :style="fontBase" v-html="question.main_text">
                                    </div>
                                    <template v-if="question.type === QuestionType.OBJECTIVE">
                                        <ObjectiveAlternatives :question="question" />
                                    </template>

                                    <template v-if="question.type === QuestionType.MATRIX">
                                        <MatrixAlternatives :question="question" />
                                    </template>

                                    <template v-if="question.type === QuestionType.TRUE_FALSE">
                                        <TrueFalseAlternatives :question="question" />
                                    </template>

                                    <template v-if="question.type === QuestionType.OPEN_ENDED">
                                        <OpenEndedAlternatives :question="question" />
                                    </template>

                                    <template v-if="question.type === QuestionType.MULTIPLE_CHOICE">
                                        <MultipleChoiceAlternatives :question="question" />
                                    </template>

                                    <template v-if="question.type === QuestionType.SORTING">
                                        <SortingAlternatives :question="question" />
                                    </template>

                                    <template v-if="question.type === QuestionType.FILL_IN_THE_BLANK">
                                        <FillInTheBlankAlternatives :question="question" />
                                    </template>

                                    <template v-if="question.type === QuestionType.CONNECT_THE_DOTS">
                                        <ConnectTheDotsAlternatives :question="question" />
                                    </template>

                                  <template v-if="question.type === QuestionType.ESSAY">
                                        <EssayAlternatives :question="question" />
                                    </template>

                                    <div v-if="question.feedback" class="relative mt-8">
                                        <div
                                          :class="{
                                            'absolute left-6 -top-[12px] px-1 bg-white': !mobile,
                                            'relative px-1 bg-white': mobile
                                          }"
                                        >
                                          <span class="text-[#808080] text-xs font-normal not-italic">Correção</span>
                                        </div>
                                        <div class="flex px-[10px] py-[10px] border-[#E8F3FC] items-start self-stretch rounded-[20px] border-2" v-html="question.feedback"></div>
                                    </div>
                                </v-main>
                            </v-sheet>
                        </v-col>
                    </template>
                    <div class="pt-[20px] pb-[60px] w-full flex justify-end items-center mr-[28px]">
                        <v-btn @click="btnAction" color="blue" class="!border-2 text-none bg-white" variant="outlined">
                            <span :style="fontSm">Sair</span>
                        </v-btn>
                    </div>
                </v-row>
            </v-col>
            <v-col cols="3" v-if="!mobile">
                <v-sheet class="py-[20px] px-[24px] rounded-xl position-fixed w-[23%] top-[13%] shadow-sheet">
                    <v-main>
                        <div class="flex justify-between">
                            <div class="flex items-center">
                                <v-avatar :image="data.application?.author.avatar"></v-avatar>
                                <div class="ml-[12px] font-bold" :style="fontLg">
                                    {{ data.application?.author?.name ?? data.application?.author?.email }}
                                </div>
                            </div>
                            <div class="bg-[#1C86E3] text-white py-2 px-3 rounded-lg text-sm">
                                <div class="text-right text-xs">
                                    Nota
                                </div>
                                <div class="text-right font-medium text-base">
                                    {{ data.userApplication?.correction?.final_grade }}<span
                                        class="text-xs opacity-70">/{{
        maxGrade }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="overflow-y-auto mt-3">

                            <template v-for="subFormQuestions of  data.questions " :key="subFormQuestions.subFormOrder">
                                <div v-for="question of (collect(subFormQuestions.questions) as any).sortBy('order')
        .all()" :key="question.id" class="flex cursor-pointer items-center mb-[12px] rounded-lg py-[8px]">
                                    <svg v-if="getQuestionFinalGrade(question) === question.grade" class="mr-2"
                                        width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#1BE477" />
                                        <path
                                            d="M14.0313 4.66663L16.6667 7.54996L8.2 15.3333L3.33333 10.4533L6.09067 7.68863L8.316 9.91996L14.0313 4.66663Z"
                                            fill="#FAFDFE" />
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#1BE477" />
                                    </svg>
                                    <svg v-else-if="getQuestionFinalGrade(question) > 0 && getQuestionFinalGrade(question) < question.grade"
                                        class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#F4C10B" />
                                        <path
                                            d="M6 10C6 9.82323 6.07024 9.65366 6.19526 9.52864C6.32029 9.40361 6.48986 9.33337 6.66667 9.33337H13.3333C13.5101 9.33337 13.6797 9.40361 13.8047 9.52864C13.9298 9.65366 14 9.82323 14 10C14 10.1769 13.9298 10.3464 13.8047 10.4714C13.6797 10.5965 13.5101 10.6667 13.3333 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10Z"
                                            fill="#FAFDFE" />
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#F4C10B" />
                                    </svg>
                                    <svg v-else-if="question.answers?.length > 0 || getQuestionFinalGrade(question) === 0"
                                        class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#F5260A" />
                                        <path
                                            d="M4.862 14.1953L9.05733 9.99997L4.862 5.80464C4.74056 5.6789 4.67336 5.5105 4.67488 5.33571C4.6764 5.16091 4.74652 4.9937 4.87012 4.87009C4.99373 4.74649 5.16093 4.67638 5.33573 4.67486C5.51053 4.67334 5.67893 4.74053 5.80467 4.86197L10 9.05731L14.1953 4.86197C14.2568 4.7983 14.3304 4.74751 14.4117 4.71257C14.4931 4.67763 14.5805 4.65924 14.6691 4.65847C14.7576 4.6577 14.8454 4.67457 14.9273 4.70809C15.0092 4.74161 15.0837 4.79111 15.1463 4.85371C15.2089 4.9163 15.2584 4.99074 15.2919 5.07267C15.3254 5.1546 15.3423 5.24239 15.3415 5.33091C15.3407 5.41943 15.3223 5.50691 15.2874 5.58824C15.2525 5.66958 15.2017 5.74314 15.138 5.80464L10.9427 9.99997L15.138 14.1953C15.2017 14.2568 15.2525 14.3304 15.2874 14.4117C15.3223 14.493 15.3407 14.5805 15.3415 14.669C15.3423 14.7576 15.3254 14.8453 15.2919 14.9273C15.2584 15.0092 15.2089 15.0836 15.1463 15.1462C15.0837 15.2088 15.0092 15.2583 14.9273 15.2919C14.8454 15.3254 14.7576 15.3422 14.6691 15.3415C14.5805 15.3407 14.4931 15.3223 14.4117 15.2874C14.3304 15.2524 14.2568 15.2016 14.1953 15.138L10 10.9426L5.80467 15.138C5.67893 15.2594 5.51053 15.3266 5.33573 15.3251C5.16093 15.3236 4.99373 15.2535 4.87012 15.1299C4.74652 15.0062 4.6764 14.839 4.67488 14.6642C4.67336 14.4894 4.74056 14.321 4.862 14.1953Z"
                                            fill="#FAFDFE" />
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#F5260A" />
                                    </svg>
                                    <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" class="mr-2"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#FAFDFE" />
                                        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#333333" />
                                    </svg>
                                    <span class="font-medium" :style="fontBase">
                                        {{ question.order }} - {{ htmlToText(question.main_text) }}...
                                    </span>
                                </div>
                            </template>
                        </div>
                    </v-main>
                </v-sheet>
            </v-col>
        </v-row>
    </div>
</template>

<script setup lang="ts">
import ConnectTheDotsAlternatives from '@/components/feedback/alternatives/ConnectTheDotsAlternatives.vue';
import FillInTheBlankAlternatives from '@/components/feedback/alternatives/FillInTheBlankAlternatives.vue';
import MatrixAlternatives from '@/components/feedback/alternatives/MatrixAlternatives.vue';
import MultipleChoiceAlternatives from '@/components/feedback/alternatives/MultipleChoiceAlternatives.vue';
import ObjectiveAlternatives from '@/components/feedback/alternatives/ObjectiveAlternatives.vue';
import OpenEndedAlternatives from '@/components/feedback/alternatives/OpenEndedAlternatives.vue';
import SortingAlternatives from '@/components/feedback/alternatives/SortingAlternatives.vue';
import TrueFalseAlternatives from '@/components/feedback/alternatives/TrueFalseAlternatives.vue';
import LogoPruvoOnlyIcon from '@/components/icons/LogoPruvoOnlyIcon.vue';
import { QuestionType } from '@/enums/question-type.enum';
import { getApplicationQuestions, getFormQuestionsFromOrganization } from '@/services/application.service';
import { getQuestionType } from '@/services/question.service';
import { useFontZoomStore } from '@/stores/font';
import collect from 'collect.js';
import { computed, onMounted, provide, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { calculateFormQuestionsGrade } from '@/services/application.service';
import { useDisplay } from 'vuetify';
import { useAuth } from '@/composables/useAuth';
import EssayAlternatives from '@/components/feedback/alternatives/EssayAlternatives.vue'

const fontSm = computed(() => useFontZoomStore().getFontSm)
const fontBase = computed(() => useFontZoomStore().getFontBase)
const fontLg = computed(() => useFontZoomStore().getFontLg)
const fontXl = computed(() => useFontZoomStore().getFontXl)
const fontZoomStore = useFontZoomStore()
const route = useRoute()
const router = useRouter()
const fontSize = computed(() => fontZoomStore.getFontZoom)
const data = ref({} as any)
const formQuestions = ref([] as any)
const maxGrade = ref(0)
const { mobile } = useDisplay();
const {
    user
} = useAuth()

const btnAction = () => {
    router.push({ name: 'dashboard' })
}

provide('formQuestions', formQuestions)

const getQuestionFinalGrade = (question: any) => {
    return question?.final_grade ?? question?.partial_grade ?? 0
}

onMounted(async () => {
    data.value = await getApplicationQuestions(route.params.applicationId as string, true)
    formQuestions.value = await getFormQuestionsFromOrganization(data.value?.application?.form_id)

    if (!data.value) {
        router.push({ name: 'dashboard' })
    }

    maxGrade.value = await calculateFormQuestionsGrade(data.value?.application?.form_ref)
})

const htmlToText = (html: string) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    const text = tmp.textContent || tmp.innerText || "";
    return text.substring(0, 19);
}
</script>